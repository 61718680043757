import { useMutation } from '@tanstack/react-query'
import { useForm } from '@kaliber/forms'
import { required, email, maxLength, minLength } from '@kaliber/forms/validation'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'
import { useUserInfo } from '/machinery/UserInfoContext'
import { useReportError } from '/machinery/ReportError'
import { throwUnexpectedResponse } from '/machinery/handleResponse'
import { BAD_REQUEST, FORBIDDEN } from '/machinery/statusCodes'
import { reportClientError } from '/machinery/reportClientError'
import { atsPhoneNumber, whitespace, noKLMRefer } from '/machinery/customValidation'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { FormFieldDropdown, FormFieldText } from '/features/pageOnly/JobApplication/FormField'
import { SendApplicationButton, FormErrorMessage } from '/features/pageOnly/JobApplication/Form'
import { BoardingPass } from '/features/pageOnly/JobApplication/BoardingPass'

import styles from './Refer.css'

export function Refer({ job, countries }) {
  return (
    <ContainerXl>
      <Form {...{ job, countries }} />
    </ContainerXl>
  )
}

function Form({ job, countries }) {
  const { __ } = useTranslate()

  const { recruiter, hiringManager, jobArea, id } = job

  const reportError = useReportError()
  const userInfo = useUserInfo()
  const [showSubmitted, setShowSubmitted] = React.useState(false)

  const { mutate, error, isPending } = useMutation({
    mutationFn: handleSendRefer,
    onError: e => {
      if (e.cause === 'kaliber') return
      reportError(e)
    },
    onSuccess: () => {
      setShowSubmitted(true)
    }
  })

  const { form, submit } = useForm({
    formId: 'refer',
    initialValues: {
      givenName: '',
      surname: '',
      email: '',
      phoneNumber: '+31',
      employeeId: userInfo.claims.employeeId,
      jobId: job.id,
      language: job.language
    },
    fields: {
      givenName: [required, minLength(2), maxLength(100)],
      surname: [required, minLength(2), maxLength(100)],
      email: [required, email, maxLength(100), whitespace, noKLMRefer],
      countryOfResidence: [required, minLength(2), maxLength(100)],
      phoneNumber: [required, whitespace, atsPhoneNumber, minLength(5), maxLength(100)],
      employeeId: [required],
      jobId: [required],
      language: [required]
    },
    onSubmit: handleSubmit,
  })

  return (
    <div className={cx(cx(styles.componentForm, showSubmitted && styles.smallGap))}>
      <div className={styles.asideText}>
        {!showSubmitted
          ? __`referral-text`
          : __`referral-success-text`
        }
      </div>

      <form onSubmit={submit} className={styles.form}>
        {!showSubmitted
          ? <FormFields {...{ form, countries, error, isPending }} />
          : <BoardingPass values={form.value.get()} layoutClassName={styles.boardingPassLayout} {...{ recruiter, hiringManager, jobArea, id }} />
        }
      </form>
    </div>
  )

  function handleSubmit({ value, invalid }) {
    if (invalid) return

    mutate({ formValues: value })
  }

  async function handleSendRefer({ formValues }) {
    const response = await fetch(routeMap.api.v1.referAFriend(), {
      body: JSON.stringify(formValues),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })

    const responseText = await response.text()

    if (response.status === FORBIDDEN) {
      const { kaliber } = JSON.parse(responseText)
      throw new Error(kaliber.errorCode, { cause: 'kaliber' })
    } else if (response.status === BAD_REQUEST) {
      const { kaliber } = JSON.parse(responseText)
      reportClientError(`Received an error with code: ${kaliber.errorCode}`)
      throw new Error(kaliber.errorCode, { cause: 'kaliber' })
    }

    if (!response.ok) throwUnexpectedResponse(response.status, responseText)
  }
}

function FormFields({ form, countries, error, isPending }) {
  const { __ } = useTranslate()
  const errorMessage = error ? (error.cause === 'kaliber' ? __`${error.message}` : __`unknown-error`) : null

  return (
    <div className={styles.componentFormFields}>
      <div className={styles.twoInputFields}>
        <FormFieldText
          required
          type='text'
          field={form.fields.givenName}
          label={__`application-form-field-givenName`}
        />
        <FormFieldText
          required
          type='text'
          field={form.fields.surname}
          label={__`application-form-field-surname`}
        />
      </div>
      <FormFieldText
        required
        type='text'
        field={form.fields.email}
        label={__`application-form-field-email`}
      />
      <FormFieldDropdown
        required
        type='text'
        field={form.fields.countryOfResidence}
        options={countries}
        label={__`application-form-field-country`}
      />
      <FormFieldText
        required
        type='text'
        field={form.fields.phoneNumber}
        label={__`application-form-field-phone`}
      />

      {errorMessage && <FormErrorMessage {...{ errorMessage }} />}

      <div className={styles.navigateContainer}>
        <SendApplicationButton label={__`send`} {...{ isPending }} />
      </div>
    </div>
  )
}
