export async function handleResponse(response) {
  const text = await response.text()
  if (!response.ok && response.status !== 422) throwUnexpectedResponse(response.status, text)

  try {
    return JSON.parse(text)
  } catch (e) {
    throwUnexpectedResponse(response.status, text)
  }
}

export function throwUnexpectedResponse(status, text) {
  throw new Error(`Error ${status}: ${text}`)
}
